.product {
  @apply flex flex-row pl-0.5;

  &:hover {
    background-color: var(--seafoam);
  }
}

img.img {
  @apply bg-white object-contain transition-all cursor-pointer;
  width: 100px;
  height: 100px;

  &:hover {
    opacity: 0.75;
  }
}

.buying-info {
  @apply flex flex-row space-x-lg justify-end;
  flex: 0 0 140px;
}

.buttons {
  @apply flex flex-col space-y-xs
  place-items-center place-content-center justify-center
  px-lg items-stretch;

  flex: 0 0 178px;
}

.img {
  flex: 0 0 100px;
}
